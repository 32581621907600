
import styled from 'styled-components';
export const HowToBuyButton = styled.button`
font-family: 'Exo 2', sans-serif;
padding: 12px 24px;
font-size: 32px;
font-weight: 600;
background-color: #ffcc00;
color: #ffffff;
border: none;
border-radius: 4px;
cursor: pointer;
transition: transform 0.3s ease;
margin: 40px 10px;
position: relative;
overflow: hidden;
z-index: 1;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);

&:hover {
  transform: scale(1.05);
  background-color: rgba(255, 255, 255, 0.3);
}

&::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200%;
  height: 200%;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.4), transparent);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

&:hover::before {
  opacity: 1;
}

&::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.4), transparent);
  transform: rotate(45deg);
  transition: transform 0.5s ease;
  z-index: -1;
}

&:hover::after {
  transform: rotate(135deg);
}

@media (max-width: 768px) {
  font-size: 16px;
  padding: 10px 20px;
  margin: 0 5px;
}
`;