import styled from 'styled-components';

const SectionDescription = styled.p`
 contain: content;
  will-change: contents;
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  background: linear-gradient(to right, #ffffff, #c0c0c0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: ${props => props.marginBottom || '60px'};
  max-width: ${props => props.maxWidth || '900px'};
  margin-left: auto;
  margin-right: auto;
  text-align: ${props => props.textalign || 'center'};

  span {
    color: #ffcc00;
    text-shadow: 0 0 5px rgba(255, 204, 0, 0.8);
  }

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: ${props => props.mobileMarginBottom || '40px'};
    max-width: ${props => props.mobileMaxWidth || '90%'};
  }
`;

export default SectionDescription;
