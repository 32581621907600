// HowToBuy.js
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaWallet, FaCoins, FaExchangeAlt } from 'react-icons/fa';
import SectionTitle from './SectionTitle';
import { HowToBuyButton } from './Button';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HowToBuyContainer = styled.section`
  background-color: transparent;
  padding: 80px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
  animation: ${fadeIn} 1s ease-in-out;

  @media (max-width: 768px) {
    padding: 60px 20px;
  }
`;




const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
`;
const Step = styled.div`
  flex-basis: 30%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
    margin-bottom: 40px;
    padding: 30px;
  }
`;

const StepIcon = styled.div`
  font-size: 64px;
  color: ${props => props.color};
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.hovercolor};
  }
`;

const StepTitle = styled.h3`
  font-family: 'Exo 2', sans-serif;
  font-size: 18px;
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.6), 0 0 16px rgba(76, 140, 47, 0.3), 3px 3px 0 #8b4513;
  -webkit-text-stroke: 1.5px #4c8c2f;
  position: relative;
  white-space: nowrap;
  display: inline-block;

  @media (max-width: 1024px) {
    font-size: 20px;
    margin-bottom: 30px;
    text-shadow: 0 0 8px rgba(255, 255, 255, 0.6), 0 0 16px rgba(76, 140, 47, 0.3), 3px 3px 0 #8b4513;
    -webkit-text-stroke: 1.5px #4c8c2f;
  }

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 20px;
    text-shadow: 0 0 4px rgba(255, 255, 255, 0.2), 0 0 8px rgba(76, 140, 47, 0.1), 1px 1px 0 #8b4513;
    -webkit-text-stroke: 0.5px #4c8c2f;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

const StepDescription = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  line-height: 1.6;
  color: #ffffff;
  position: relative;
  z-index: 1;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.8);
  background: linear-gradient(to right, #ffffff, #c0c0c0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;



const HowToBuy = () => {
  return (
    <React.Fragment>
    <HowToBuyContainer id='how-to-buy'>
      <SectionTitle>How to Buy $FARTI</SectionTitle>
      <StepContainer>
      <Step>
  <StepIcon color="#8b4513" hovercolor="#a0522d">
    <FaWallet />
  </StepIcon>
  <StepTitle>Step 1: Create a Wallet</StepTitle>
  <StepDescription>
    Download a wallet like Phantom from the App Store or Google Play Store.
    For desktop users, download the Google Chrome extension.
  </StepDescription>
</Step>
        <Step>
          <StepIcon color="#ffc107" hovercolor="#ffd54f">
            <FaCoins />
          </StepIcon>
          <StepTitle>Step 2: Have SOL in Wallet</StepTitle>
          <StepDescription>
            Ensure you have SOL in your wallet to switch to $FARTI. You can buy SOL
            from an exchange or cross-chain swap and send it to your wallet.
          </StepDescription>
        </Step>
        <Step>
          <StepIcon color="#2196f3" hovercolor="#64b5f6">
            <FaExchangeAlt />
          </StepIcon>
          <StepTitle>Step 3: Switch SOL for $FARTI</StepTitle>
          <StepDescription>
            Connect to Radium at radium.io. Paste the $FARTI token address into Radium
            and confirm the swap. Sign the wallet signature when prompted.
          </StepDescription>
        </Step>
      </StepContainer>
      <HowToBuyButton>Buy $FARTI Now!</HowToBuyButton>
    </HowToBuyContainer>
    </React.Fragment>
  );
};

export default HowToBuy;